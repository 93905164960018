<template>
  <svg width="width" :height="height" :fill="color" viewBox="0 0 24 24" id="invoice-dollar-done" data-name="Flat Line" xmlns="http://www.w3.org/2000/svg" class="icon flat-line"><path id="secondary" d="M11,10a8,8,0,0,1,4.13-7H5A1,1,0,0,0,4,4V20a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V17.93A8,8,0,0,1,11,10Z" style="fill: rgb(44, 169, 188); stroke-width: 1;"></path><polyline id="primary" points="11 10.5 9.09 13 8 11.75" style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 1;"></polyline><path id="primary-2" data-name="primary" d="M20,7H17.5A1.5,1.5,0,0,0,16,8.5h0A1.5,1.5,0,0,0,17.5,10h1A1.5,1.5,0,0,1,20,11.5h0A1.5,1.5,0,0,1,18.5,13H16" style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 1;"></path><path id="primary-3" data-name="primary" d="M11,17H8M18,7V6m0,8V13" style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 1;"></path><path id="primary-4" data-name="primary" d="M15,3H5A1,1,0,0,0,4,4V20a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V18" style="fill: none; stroke: rgb(0, 0, 0); stroke-linecap: round; stroke-linejoin: round; stroke-width: 1;"></path></svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
