<template>
  <div>
    <!-- <div class="bottom_space rounded py-2 h-full bg-white">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 row-span-1">
        <div style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Competition' })">
          <div class="  flex rounded-l-lg  justify-center items-center w-32 bg-green-500">
          <i class="h-12 w-12 text-white fas fa-graduation-cap" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
             Competitions
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Users' })">
          <div class="rounded-l-lg  flex justify-center items-center w-32 bg-blue-500">
          <i class="h-12 w-12 text-white fas fa-users" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Users
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Voters' })">
          <div class="rounded-l-lg   flex justify-center items-center w-32 bg-purple-600">
          <i class="h-12 w-12 text-white fas fa-tags" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Voters
          </p>
        </div>
        <div  style="height: 100px;" class="flex shadow cursor-pointer rounded-lg bg-white  hover:shadow-2xl" @click="$router.push({ name: 'Sponsors' })">
          <div class="rounded-l-lg   flex justify-center items-center w-32 bg-secondary">
          <i class="h-12 w-12 text-white fas fa-suitcase" ></i>
          </div>
          <p class="text-right  heading-1  self-center pl-9">
            Sponsors
          </p>
        </div>
      </div>
    </div> -->
    <div class="rounded h-full bg-white mt-6">
      <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 row-span-1 gap-y-12">
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Organizations' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Total Organizations</div>
              <div class="heading-2 text-text2">{{ dashboardData.organizations }}</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <organizationSvg color="white" :width="'80px'" :height="'80px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Users' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Total Users</div>
              <div class="heading-2 text-text2">{{ dashboardData.teamMembers }}</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <usersSvg color="white" :width="'55px'" :height="'55px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Customers' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Total Customers</div>
              <div class="heading-2 text-text2">{{ dashboardData.customers }}</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <customerSvg color="white" :width="'70px'" :height="'70px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="$router.push({ name: 'Leads' })">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Total Leads</div>
              <div class="heading-2 text-text2">{{ dashboardData.leads }}</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <leadSvg color="white" :width="'70px'" :height="'70px'"/>
            </div>
          </div>
        </div>
        <div class="relative cursor-pointer hover:shadow-xl transition delay-100" @click="goToAttchment()">
          <div class="flex h-28 w-full rounded-lg bg-white cust_card justify-end">
            <span class="my-auto  pr-12  text-center">
              <div class="heading-2 text-text2">Total Storage Used</div>
              <div class="heading-2 text-text2">{{ dashboardData.storageUsed | getFileSize}}</div>
            </span>
          </div>
          <div class="absolute top-0 left-6 -mt-2 h-32 w-32 rounded-full border-2 bg-blue-200 shadow-md inline-flex">
            <div class=" flex justify-center items-center w-32 ">
              <memorySvg color="white" :width="'70px'" :height="'70px'"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import memorySvg from '@/assets/images/svg/memorySvg.vue'
import usersSvg from '@/assets/images/svg/usersSvg.vue'
import organizationSvg from '@/assets/images/svg/organizationSvg.vue';
import customerSvg from '@/assets/images/svg/customerSvg.vue';
import leadSvg from '@/assets/images/svg/leadSvg.vue';
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    memorySvg,
    usersSvg,
    customerSvg,
    leadSvg,
    organizationSvg
  },
  data () {
    return {
      dashboardData: [],
    }
  },
  created() {
  },
  mounted () {
    this.getCompetDetail()
  },
  watch: {
  },
  methods: {
    goToAttchment () {
      this.$router.push({name: 'attachmentList', query: {fromorgid: 0}})
    },
    getCompetDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.DashboardOverview(
        response => {
          this.dashboardData = response.Data === null ? [] : response.Data
          console.log("response 123", this.dashboardData)
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
