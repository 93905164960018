<template>
  <svg :width="width" :height="height" :fill="color" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <!-- <circle style="fill:#88C5CC;" cx="256" cy="256" r="256"/> -->
    <path style="fill:#406A80;" d="M396,236v-96.636L340.636,84H132c-8.8,0-16,7.2-16,16v136h16v60h-16v116c0,8.8,7.2,16,16,16h248c8.8,0,16-7.2,16-16V268h-12v-32H396z"/>
    <g><rect x="144" y="84" style="fill:#FFD464;" width="32" height="128"/><rect x="184" y="84" style="fill:#FFD464;" width="32" height="88"/><rect x="224" y="84" style="fill:#FFD464;" width="32" height="88"/><rect x="264" y="84" style="fill:#FFD464;" width="32" height="88"/><rect x="304" y="84" style="fill:#FFD464;" width="32" height="88"/><rect x="344" y="124" style="fill:#FFD464;" width="32" height="88"/></g><rect x="116" y="248" style="fill:#E78244;" width="16" height="24"/>
  </svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: '#0f0101'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
